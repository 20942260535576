import React from 'react'
import {useIntl} from 'react-intl'
import styles from './ErrorMessage.module.scss'

export const ErrorMessage = props => {
  const {errors} = props
  const intl = useIntl()

  let error = errors.join('')
  const intlError = intl.formatMessage({id: error})

  if(intlError !== error) {
    error = intlError
  }

  return (
    <div>
      <div className={styles.title}>
        {intl.formatMessage({id: 'fatal_errors'})}
      </div>
      <div className={styles.error}>
        {error}
      </div>
    </div>
  )
}
