export const ru = {
  'order': 'Заказ {order}',
  'amount': '{amount}',

  'card.sample.expiry': 'ММ / ГГ',
  'card.sample.name': 'ИМЯ ФАМИЛИЯ',

  'card.pan': 'Номер карты',
  'card.owner': 'Имя и фамилия как на карте',
  'card.expiry': 'ММ / ГГ',
  'card.cvc': 'CVC',
  'card.captcha_text': 'Введите символы с картинки',

  'card.btn.pay': 'Оплатить',

  'card.error.pan.required': 'Заполните поле.',
  'card.error.pan.invalid': 'Неверный номер карты.',
  'card.error.name.invalid': 'Нужны лат. символы, пробелы, точка, тире (до 30).',
  'card.error.name.required': 'Заполните поле.',
  'card.error.cvc.required': 'Заполните поле.',
  'card.error.cvc.invalid': 'Заполните поле.',
  'card.error.expiry.required': 'Заполните поле.',
  'card.error.expiry.month.invalid': 'Значение от 01 до 12.',
  'card.error.expiry.year.invalid': 'Текущий или следующие годы.',
  'card.error.captcha_text.required': 'Заполните поле.',

  'card.protected.caption.title': 'Ваши данные под защитой',
  'card.protected.caption.desc': 'Реквизиты банковской карты передаются по защищенным протоколам и не попадут в интернет-магазин и третьим лицам. Платежи обрабатываются на защищенной странице процессинга по стандарту PCI DSS',

  'card.sending.preloader.title': 'Не закрывайте окно и не обновляйте страницу',
  'card.sending.preloader.desc': 'Мы формируем ссылку. Это может занять от нескольких секунд до минуты.',

  'fatal_errors': 'Причина отказа:',

  'card.processing.1': 'Подготовка данных',
  'card.processing.2': 'Проверка возможности оплаты',
  'card.processing.3': 'Проведение платежа',

  'finish.pending': 'Обработка платежа',
  'finish.pending.desc': 'Ваш платеж принят в обработку. Закройте это окно и вернитесь в магазин.',
  'finish.complete': 'Платеж принят',
  'finish.complete.desc': 'Оплата прошла успешно. Закройте это окно и вернитесь в магазин.',
  // 'finish.failed': 'При обработке платежа произошла ошибка.',
  'finish.failed': 'Платеж отклонен',
  'finish.failed.desc': 'Пожалуйста, вернитесь в магазин для повторной оплаты.',
  'finish.refunded': 'Платеж возвращен',
  'finish.refunded.desc': ' ',
  'finish.exception': 'Что-то пошло не так',
  'finish.exception.desc': ' ',

  'finish.debug_info': 'Информация для обращения в поддержку',
  'return.btn.return': 'Вернуться в магазин',

  'error.general': 'Что-то пошло не так... попробуйте еще раз.',
}
